import React from 'react';
import { graphql, Link } from 'gatsby';

import {
    layout,
    heading as headingClass,
    subheading as subheadingClass,
    link as linkClass,
} from './newsletter-thank-you-page.module.scss';
import { useTranslation } from '../hooks/use-translation';
import { translationKeys } from '../config/translation-keys';
import { ISitePageContext } from '../models/site-page.model';

import ContentLayout from '../layouts/content-layout';
import Markdown from '../components/hoc/markdown';

interface IThankYouPageProps {
    readonly pageContext: ISitePageContext;
}

const ThankYouPage: React.FC<IThankYouPageProps> = ({ pageContext }) => {
    const { label, subLabel, linkText } = useTranslation(pageContext, translationKeys.pageContext);

    return (
        <ContentLayout SEOProps={{ title: label }} className={layout}>
            <h1 className={headingClass}>{label}</h1>
            {subLabel && <Markdown className={subheadingClass}>{subLabel}</Markdown>}
            {linkText && (
                <Link className={linkClass} to={'/'}>
                    {linkText}
                </Link>
            )}
        </ContentLayout>
    );
};

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default ThankYouPage;
